<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>咨询评论管理</el-breadcrumb-item>
      <el-breadcrumb-item>咨询记录</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="queryname">
            <div style="display: inline-block" class="divSpan">咨询产品：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.queryname"
                :options="NameOptions"
                :optionKey="NameOptionKeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="querytype">
            <div style="display: inline-block" class="divSpan">咨询类型：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.querytype"
                :options="TypeOptions"
                :optionKey="NameOptionKeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="recordList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="userName"
            label="姓名"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="company"
            label="企业名称"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="position"
            label="职位"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="area"
            label="所在城市"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="咨询产品"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="咨询类型"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="咨询内容"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="咨询时间"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="处理状态"
            min-width="100px"
          ></el-table-column>

          <el-table-column label="操作" min-width="100px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewComment(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'

export default {
  components: { ELselect },

  data () {
    return {
      queryinfo: {
        queryname: '',
        querytype: '',
        pageNum: 1,
        pageSize: 8
      },
      total: 0,
      recordList: [],

      NameOptionKeys: {
        value: 'type',
        label: 'name',
        label2: ''
      },
      NameOptions: [
        {
          name: '全部',
          type: ''
        },
        {
          name: '物联网平台',
          type: 1
        },
        {
          name: '边缘一体机',
          type: 2
        }
      ],
      TypeOptions: [
        {
          name: '全部',
          type: ''
        },
        {
          name: '购买咨询',
          type: 1
        },
        {
          name: '合作咨询',
          type: 2
        }
      ],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () { },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.queryinfo.pageSize = 8
      this.getResourceList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getResourceList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getResourceList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    viewComment (para) {
      console.log(para)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-form-item__content {
  margin-right: 20px;
}
</style>