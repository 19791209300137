var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/home' }}},[_vm._v("主页")]),_c('el-breadcrumb-item',[_vm._v("咨询评论管理")]),_c('el-breadcrumb-item',[_vm._v("咨询记录")])],1),_c('el-card',[_c('el-row',{attrs:{"gutter":30}},[_c('el-form',{ref:"queryinfoRef",staticStyle:{"text-align":"left","margin-left":"40px"},attrs:{"model":_vm.queryinfo,"inline":true}},[_c('el-form-item',{attrs:{"prop":"queryname"}},[_c('div',{staticClass:"divSpan",staticStyle:{"display":"inline-block"}},[_vm._v("咨询产品：")]),_c('div',{staticStyle:{"display":"inline-block"}},[_c('ELselect',{staticStyle:{"width":"140px"},attrs:{"options":_vm.NameOptions,"optionKey":_vm.NameOptionKeys,"Splaceholder":"请选择"},on:{"clear":_vm.getList},model:{value:(_vm.queryinfo.queryname),callback:function ($$v) {_vm.$set(_vm.queryinfo, "queryname", $$v)},expression:"queryinfo.queryname"}})],1)]),_c('el-form-item',{attrs:{"prop":"querytype"}},[_c('div',{staticClass:"divSpan",staticStyle:{"display":"inline-block"}},[_vm._v("咨询类型：")]),_c('div',{staticStyle:{"display":"inline-block"}},[_c('ELselect',{staticStyle:{"width":"140px"},attrs:{"options":_vm.TypeOptions,"optionKey":_vm.NameOptionKeys,"Splaceholder":"请选择"},on:{"clear":_vm.getList},model:{value:(_vm.queryinfo.querytype),callback:function ($$v) {_vm.$set(_vm.queryinfo, "querytype", $$v)},expression:"queryinfo.querytype"}})],1)]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.seachChange}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"info","icon":"el-icon-refresh"},on:{"click":_vm.clean}},[_vm._v("重置")])],1)],1),_c('el-row',{staticStyle:{"display":"flex"},attrs:{"gutter":30}},[_c('el-table',{attrs:{"data":_vm.recordList,"header-cell-style":{
          textAlign: 'center',
          background: '#FAFAFA',
          color: 'rgba(0, 0, 0, 0.85)',
          fontWeight: '500',
        },"tooltip-effect":"dark","cell-style":{
          textAlign: 'center',
        }}},[_c('el-table-column',{attrs:{"type":"index"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"姓名","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"手机号","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"company","label":"企业名称","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"position","label":"职位","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"用户ID","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"area","label":"所在城市","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"咨询产品","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"咨询类型","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"咨询内容","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"咨询时间","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userId","label":"处理状态","min-width":"100px"}}),_c('el-table-column',{attrs:{"label":"操作","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.viewComment(scope.row)}}},[_vm._v("详情")])]}}])})],1)],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryinfo.pageNum,"page-size":_vm.queryinfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }